<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-surface: #1D1D1F;
  --color-primary: #06c;
}

body {
  background-color: var(--color-surface);
}
</style>
