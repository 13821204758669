<template>
    <div class="welcome">
        <div class="welcome-list">
            <button
                v-for="item in items"
                :key="item.id"
                class="welcome-list__item"
                @click="$router.push(`/showcase/${item.id}`)"
            >
                {{ item.title }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                id: 'ccLAPnPDwsbusxTM7STJ',
                title: 'Bloom',
            },
            {
                id: 'YY258yO6e69p3w3Dmvy9',
                title: 'Bloom 2',
            },
            {
                id: 'mEQu1AshCXW6y5JkTDdw',
                title: 'Elegant',
            },
            {
                id: 'pkHkZwsUAtxMuhTNN6n1',
                title: 'Inspiration',
            },
            {
                id: 'JAjd4jog5RlrLWntgPg1',
                title: 'Minimalistic',
            }
        ]
    })
}
</script>

<style scoped>
.welcome {
    padding: 15px;
    min-height: 100dvh;
}
.welcome-list {
    display: flex;
    flex-direction: column;
}
.welcome-list__item {
    height: 48px;
    width: 100%;
    color: var(--color-primary);
}
.welcome-list__item:not(:last-child) {
    margin-bottom: 15px;
}
</style>