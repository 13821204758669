<template>
    <div
        :style="styles"
        class="demo-view"
    >
        <iframe
            frameborder="0"
            class="demo-frame"
            :src="previewUrl"
        />
        <button
            class="btn-back"
            @click="$router.push('/')"
        >
            Back
        </button>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const previewUrl = computed(() => {
    return `https://template.invito.link/?preview=${route.params.id}&showcase=true`
});

const styles = computed(() => ({
    '--view-height': `${window.innerWidth * 1.94444}px`
}));
</script>

<style scoped>
.demo-view {
    width: 100%;
    height: 100dvh;
}
.demo-frame {
    width: 100%;
    height: var(--view-height);
}
.btn-back {
    height: calc(100dvh - var(--view-height));
    width: 100%;
    border: none;
    font-size: 20px;
}
</style>