import { createRouter, createWebHashHistory } from 'vue-router'

import ShowcaseView from '@/components/ShowcaseView'
import WelcomeView from '@/components/WelcomeView'

const router = createRouter ({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            component: WelcomeView
        },
        {
            path: '/showcase/:id',
            component: ShowcaseView
        }
    ]
});

export default router;